import axios from "axios";
import Cookies from "js-cookie";
import { SessionKey } from "../values/constants";
import { signOut, getAuth } from "firebase/auth";
import { firebaseConfig } from "./firebaseConfig";
import { initializeApp } from "@firebase/app";

const MyAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization:
      Cookies.get(SessionKey.token) != null
        ? `Bearer ${Cookies.get(SessionKey.token)}`
        : null,
  },
});

MyAxios.interceptors.response.use(
  (response) => {
    // If the request is successful, return the response
    return response;
  },
  (error) => {
    if (error?.response?.data?.message?.code === 401) {
      const app = initializeApp(firebaseConfig);
      const auth = getAuth(app);
      signOut(auth);
      Cookies.remove(SessionKey.profileImg);
      Cookies.remove(SessionKey.profileName);
      Cookies.remove(SessionKey.token);
      Cookies.remove(SessionKey.userId);
      const kbmLink = process.env.REACT_APP_REDIRECT_KBM;
      const redirectLink = `${kbmLink}/logout-write`;
      window.location.replace(redirectLink);
    }

    // Reject the promise so the error can be handled further down if necessary
    return Promise.reject(error);
  }
);

export default MyAxios;
